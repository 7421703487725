import axios from 'axios';
function convertToArrayWithUserId(obj) {
    const keys = Object.keys(obj);
    const result = [];
    for (let i = 0; i < keys.length; i++) {
        const newObj = { ...obj[keys[i]], user_id: keys[i] };
        result.push(newObj);
    }
    return result;
}
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://pending.backend-tree.com'
});
export const getInventory = async (userId) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/check_view_inventory/${userId}`);
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const searchCharacters = async (nickname) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/search_users/${nickname}`);
        return response.data.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const getRbxInStock = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/in_stock');
        return response.data.robux_amount;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const getGiftsStock = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/in_stock_gifts');
        return response.data.gifts;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const getActiveOrders = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/active_orders');
        return response.data.orders;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const makeOrderTransfer = async (robuxAmount, placeId, universeId, username, promoCode, flag) => {
    try {
        const promo = promoCode ? { promo: promoCode } : {};
        const response = await AXIOS_INSTANCE.get('/make_order', {
            params: {
                robux_amount: robuxAmount,
                place_id: placeId,
                universe_id: universeId,
                gamepass: true,
                username,
                flag,
                ...promo
            }
        });
        return response.data;
    }
    catch (error) {
        return null;
    }
};
export const makeOrderGift = async (robuxAmount) => {
    try {
        const response = await AXIOS_INSTANCE.get('/make_order_gift', {
            params: {
                robux_amount: robuxAmount,
                currency: 'RUB',
            }
        });
        return response.data;
    }
    catch (error) {
        return null;
    }
};
export const makeOrderPlugin = async (robuxAmount, assetId, userId, username, flag) => {
    try {
        const response = await AXIOS_INSTANCE.get('/make_order', {
            params: {
                plugin: true,
                asset_id: assetId,
                roblox_user_id: userId,
                robux_amount: robuxAmount,
                username,
                flag
            }
        });
        return response.data;
    }
    catch (error) {
        return null;
    }
};
export const getPlugins = async (userId) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/get_plugins/${userId}`);
        return response.data.data;
    }
    catch (error) {
        return [];
    }
};
export const validatePlugin = async (assetId, price) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/validate_plugin/${assetId}/${price}`);
        return response.data.status;
    }
    catch (error) { }
    return false;
};
export const getPasses = async (uid) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/get_passes/${uid}`);
        if (response.data?.data) {
            return response.data.data;
        }
        else
            return null;
    }
    catch (error) { }
    return [];
};
export const getPlaces = async (userId) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/get_places/${userId}`);
        if (response.data.status) {
            return Object.entries(response.data.data).map(([placeId, place]) => {
                return {
                    placeId,
                    name: place.name,
                    imageUrl: place.imageUrl,
                    universeID: place.UniverseID
                };
            });
        }
    }
    catch (error) {
        console.error(error);
    }
    return null;
};
export const getHistory = async (count, page) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/get_history?count=${count}&page=${page}`);
        return {
            totalPages: response.data.total_pages,
            totalOrders: response.data.total_orders,
            orders: response.data.orders
        };
    }
    catch (error) {
        return null;
    }
};
export const getHistoryGifts = async (count, page) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/get_history_gifts?count=${count}&page=${page}`);
        return {
            totalPages: response.data.total_pages,
            totalOrders: response.data.total_orders,
            orders: response.data.orders
        };
    }
    catch (error) {
        return null;
    }
};
export const searchUsers = async (nickname) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/search_users/${nickname}`);
        return response.data?.data ? convertToArrayWithUserId(response.data.data) : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const applyPromo = async (promoCodeValue, totalRobux) => {
    try {
        const response = await AXIOS_INSTANCE.get('/check_promo', {
            params: {
                promo: promoCodeValue,
                robux_amount: totalRobux
            }
        });
        return response?.data;
    }
    catch (error) {
        console.error(error);
        return error?.response.data;
    }
};
