import { useNotificationStore } from 'entities/notification';
export function getCurrencySymbol(value) {
    const currencyData = {
        rub: '₽',
        eur: '€',
        usd: '$'
    };
    return currencyData[value];
}
/**
 * Converts a timestamp to a formatted date and time string.
 *
 * @param {string} _date - The timestamp to be converted.
 * @return {string} The formatted date and time string.
 */
export function normalizeTime(_date) {
    const date = new Date(_date);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
    return formattedDateTime;
}
/**
 * Normalizes the given amount value by adding a space every two characters.
 *
 * @param {string} value - The amount value to be normalized.
 * @return {string} The normalized amount value.
 */
export function normalizeAmount(value) {
    let formattedStr = '';
    for (let i = value.length - 1, count = 0; i >= 0; i--) {
        formattedStr = value.charAt(i) + formattedStr;
        if (count === 2 && i !== 0) {
            formattedStr = ' ' + formattedStr;
            count = 0;
        }
        else {
            count++;
        }
    }
    return formattedStr;
}
export function validateEmailRegex(email) {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegExp.test(String(email));
}
export function copyText(text, message) {
    const notificationStore = useNotificationStore();
    const description = message || 'ID скопирован в буфер обмена';
    navigator.clipboard.writeText(text);
    notificationStore.setNotification({
        description,
        title: 'Успешно',
        type: 'success'
    });
}
export function validateLength(text, min, max) {
    return (text.length >= min && text.length <= max);
}
export function validatePasswordRegex(password) {
    const passRegExp = /^(?=.*[a-z])(?=.*[A-Z]).{6,18}$/;
    return passRegExp.test(String(password));
}
export function validatePassword(password) {
    if (password.length === 0) {
        return 'Поле пустое';
    }
    else if (password.length < 6) {
        return 'Допустимая длина пароля от 6 символов';
    }
    else if (!validatePasswordRegex(password)) {
        return 'Пароль должен содержать маленькие и большие буквы';
    }
    else {
        return '';
    }
}
export function validatePasswordRepeat(password, repeatPassword) {
    if (repeatPassword !== password) {
        return 'Пароли не совпадают';
    }
    else {
        return '';
    }
}
export function normalizeTaxPercent(tax) {
    return parseFloat(Number((1 - tax) * 100).toFixed(1));
}
export function calculateAmountWithTax(amount, tax) {
    const percent = (1 - tax) * 100;
    return (Number(amount) + (Number(amount) * percent) / 100).toFixed(2);
}
export function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
export function makeCanonicalLink(routePath) {
    const baseFrontendURL = 'https://rbxtree.io';
    return `${baseFrontendURL}${routePath}`;
}
export function normalizeRubAmount(number) {
    return Number.isInteger(number) ? number : number.toFixed(2);
}
